import Checkmark from 'assets/icons/check.svg?react';
import LogoutButton from 'auth/LogoutButton';
import { Divider } from 'components/elements/Divider';
import { TextEllipsis } from 'components/info/TextEllipsisComponent';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    borderRadiusS,
    font,
    headerHeight,
    iconSizeS,
    lightBlue,
    lightGrey,
    menuBoxShadow,
    spaceS,
    spaceXs,
    spaceXxs,
    white,
} from 'styles/variables';
import { ButtonVariant } from 'types';
import { Business } from 'types/business';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 18.5rem;
    padding: ${spaceS};
    position: absolute;
    background-color: ${white};
    box-shadow: ${menuBoxShadow};
    transition: height 1s ease;
    border-radius: 0 0 ${borderRadiusS} ${borderRadiusS};
    top: calc(${headerHeight} + 1px);
    right: 0;
    font-size: ${font.size.s};
    line-height: ${font.lineHeight.m};
    overflow: hidden;

    .user-name,
    .accounts-title {
        margin: 0;
        line-height: ${font.lineHeight.m};
        font-weight: ${font.weight.bold};
    }

    .user-email {
        line-height: ${font.lineHeight.m};
        margin: 0;
    }
`;

const OrganisationList = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${spaceXxs} -${spaceS} 0 -${spaceS};
    max-height: 20rem;
    overscroll-behavior: contain;
    overflow-x: hidden;
    overflow-y: auto;

    label {
        display: grid;
        grid-template-columns: 1fr ${iconSizeS};
        align-items: center;
        padding: ${spaceXs} ${spaceS};
        width: 100%;
        grid-template-rows: minmax(1.5rem, auto);
        font-size: ${font.size.s};
        &:hover,
        &:focus,
        &:active {
            background: ${lightBlue};
        }
    }
    input {
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
    }
`;

const StyledCheckmark = styled(Checkmark)`
    align-self: start;
    height: ${iconSizeS};
    width: ${iconSizeS};
    margin-top: 2px;
`;

const StyledLogoutButton = styled(LogoutButton)`
    justify-content: start;
    background: ${white};
    padding: ${spaceS};
    font-size: ${font.size.s};
    font-family: ${font.body};
    margin: -${spaceXs} -${spaceS} -${spaceS} -${spaceS};
    border-radius: 0;
    &.btn--text {
        color: ${font.color.default};
        text-decoration: none;
        &:hover {
            color: ${font.color.default};
            background: ${lightBlue};
        }
    }
`;
const StyledDivider = styled(Divider)`
    background: ${lightGrey};
`;

type UserNameProps = {
    userName: string;
    userEmail: string;
    onOrganisationChange: (parentBusinessId: string) => void;
    businesses: Business[];
    activeBusinessId?: string;
};

function UserMenu({
    userName,
    userEmail,
    onOrganisationChange,
    businesses,
    activeBusinessId,
}: UserNameProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <Container data-testid="userMenu">
            <TextEllipsis className="user-name">{userName}</TextEllipsis>
            <TextEllipsis className="user-email">{userEmail}</TextEllipsis>
            <StyledDivider $margin="1rem" />
            <p className="accounts-title">{t('userMenu.accountsTitle')}</p>
            <OrganisationList data-testid="accountList">
                {businesses.map((parentBusiness) => (
                    <label
                        htmlFor={parentBusiness.id}
                        className={activeBusinessId === parentBusiness.id ? 'active' : ''}
                        key={parentBusiness.id}
                    >
                        <input
                            type="radio"
                            name="organisation"
                            value={parentBusiness.id}
                            id={parentBusiness.id}
                            data-testid={`organisationListItem_${parentBusiness.id}`}
                            checked={activeBusinessId === parentBusiness.id}
                            onChange={() => onOrganisationChange(parentBusiness.id)}
                        />
                        <span>{parentBusiness.name}</span>
                        {activeBusinessId === parentBusiness.id && <StyledCheckmark />}
                    </label>
                ))}
            </OrganisationList>
            <StyledDivider $margin="0.5rem" />
            <StyledLogoutButton variant={ButtonVariant.TEXT} />
        </Container>
    );
}

export default UserMenu;

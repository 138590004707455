import welcomeImage from 'assets/images/onboarding/welcome.webp';
import useAuthorization from 'hooks/useAuthorization';
import useLocalState from 'hooks/useLocalState';
import { t } from 'i18next';
import { useState } from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import styled from 'styled-components';
import { borderRadiusM, colorModalOverlay, font, primaryMerBlue, spaceM, spaceS, spaceXs } from 'styles/variables';
import { FEATURE_LIST } from 'types';
import { EBusinessFeature } from 'types/business';
import { showFeature } from 'utils/featureFlag';
import { scrollToTop } from 'utils/scrollToTop';

const Content = styled.div`
    text-align: left;
    font-size: ${font.size.m};
    h1 {
        font-family: ${font.body};
        font-size: ${font.size.l};
        font-weight: ${font.weight.semiBold};
        line-height: ${font.lineHeight.l};
        margin-bottom: ${spaceXs};
    }
    p {
        margin-bottom: ${spaceM};
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin-bottom: ${spaceM};
    }
`;

const contentElement = (title: string, description: string, image?: string): React.ReactNode => {
    return (
        <Content>
            <h1>{title}</h1>
            <p>{description}</p>
            {image && <img src={image} alt="text" />}
        </Content>
    );
};

function OnboardGuide(): React.ReactNode {
    const [steps] = useState<Step[]>([
        {
            target: 'body',
            content: contentElement(
                t('startpage.onboarding.welcome.title'),
                t('startpage.onboarding.welcome.description'),
                welcomeImage,
            ),
            placement: 'center',
            disableBeacon: true,
        },
        {
            target: '.userMenu',
            content: contentElement(t('startpage.onboarding.step1.title'), t('startpage.onboarding.step1.description')),
            disableBeacon: true,
        },
        {
            target: '.navigationMenu_subscription',
            content: contentElement(t('startpage.onboarding.step2.title'), t('startpage.onboarding.step2.description')),
            placement: 'right',
            disableBeacon: true,
        },
        {
            target: '.subscriptionKPISection',
            content: contentElement(t('startpage.onboarding.step3.title'), t('startpage.onboarding.step3.description')),
            disableBeacon: true,
        },
        {
            target: '.navigationMenu_facility',
            content: contentElement(t('startpage.onboarding.step4.title'), t('startpage.onboarding.step4.description')),
            placement: 'right',
            disableBeacon: true,
        },
        {
            target: '.helpSection',
            content: contentElement(t('startpage.onboarding.step5.title'), t('startpage.onboarding.step5.description')),
            placement: 'top',
            disableBeacon: true,
        },
    ]);

    const {
        checkAuthorizationFeatureArray,
        error: errorAuthorization,
        isLoading: isLoadingAuthorization,
    } = useAuthorization();

    const {
        actions: { setDontShowOnboardGuideAnymore },
        state: { onboardingGuideHasBeenShown },
    } = useLocalState();

    const handleJoyrideCallback = (data: CallBackProps): void => {
        const { status } = data;
        if (status === 'finished' || status === 'skipped') {
            setDontShowOnboardGuideAnymore();
            scrollToTop();
        }
    };

    const showOnboardingGuide =
        !isLoadingAuthorization &&
        !errorAuthorization &&
        !onboardingGuideHasBeenShown &&
        showFeature(FEATURE_LIST.SHOW_ONBOARD_GUIDE) &&
        checkAuthorizationFeatureArray([EBusinessFeature.FACILITY_MANAGEMENT_EDIT]) &&
        checkAuthorizationFeatureArray([EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT]);

    return (
        showOnboardingGuide && (
            <Joyride
                callback={handleJoyrideCallback}
                continuous
                showProgress
                showSkipButton
                steps={steps}
                disableOverlayClose
                scrollToFirstStep
                scrollOffset={70}
                locale={{
                    skip: t('tourGuide.skip'),
                    next: t('tourGuide.next'),
                    back: t('tourGuide.back'),
                    last: t('tourGuide.last'),
                }}
                styles={{
                    options: {
                        zIndex: 10000,
                        width: 419,
                    },
                    overlay: {
                        height: '250%',
                        backgroundColor: colorModalOverlay,
                    },
                    buttonNext: {
                        backgroundColor: primaryMerBlue,
                        fontFamily: font.semiBold,
                        fontSize: font.size.m,
                        padding: `${spaceXs} ${spaceS}`,
                        lineHeight: font.lineHeight.l,
                    },
                    buttonBack: {
                        color: primaryMerBlue,
                        fontFamily: font.semiBold,
                        fontSize: font.size.m,
                        marginRight: spaceS,
                    },
                    buttonSkip: {
                        color: primaryMerBlue,
                        fontSize: font.size.m,
                        fontFamily: font.semiBold,
                    },
                    buttonClose: {
                        display: 'none',
                    },
                    tooltip: {
                        padding: spaceM,
                        borderRadius: borderRadiusM,
                    },
                    tooltipContent: {
                        padding: '0',
                    },
                    tooltipFooter: {
                        margin: '0',
                    },
                }}
            />
        )
    );
}

export default OnboardGuide;

import useAuthentication from './useAuthentication';
import { decodeToken } from './useToken';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import axios, { AxiosError, ResponseType } from 'axios';
import ApiError from 'classes/ApiError';
import { useAppInsights } from 'contexts/AppInsightsContext';

const api = axios.create({
    baseURL: import.meta.env.VITE_APP_MERHUB_BFF,

    headers: {
        accept: 'application/json',
    },
});

enum Method {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

type ApiRequestOptions = {
    headers?: ApiRequestHeaders;
    download?: boolean;
};

type ApiRequestMethod<TResponse> = (url: string, options?: ApiRequestOptions) => Promise<TResponse>;

type ApiRequestPayloadMethod<TResponse, TPayload> = (
    url: string,
    payload: TPayload,
    options?: ApiRequestOptions,
) => Promise<TResponse>;

type ApiRequestHeaders = Record<string, string>;

type useApiResult<TResponse, TCreatePayload, TUpdatePayload> = {
    get: ApiRequestMethod<TResponse>;
    post: ApiRequestPayloadMethod<TResponse, TCreatePayload>;
    patch: ApiRequestPayloadMethod<TResponse, TUpdatePayload>;
    put: ApiRequestPayloadMethod<TResponse, TUpdatePayload>;
    del: ApiRequestMethod<TResponse>;
};

const trackException: (appInsights: ApplicationInsights, error: AxiosError) => void = (
    appInsights: ApplicationInsights,
    error: AxiosError,
) => {
    appInsights.trackException({
        exception: new Error(error.message),
        severityLevel: 3,
        properties: {
            url: error.config?.url,
            method: error.config?.method,
            status: error.response ? error.response.status : 'Network Error',
        },
    });
};

const useApi: <TResponse = undefined, TCreatePayload = undefined, TUpdatePayload = undefined>() => useApiResult<
    TResponse,
    TCreatePayload,
    TUpdatePayload
> = () => {
    const { acquireAccessTokenSilent } = useAuthentication();
    const { appInsights } = useAppInsights();

    const request: <TResponse, TPayload>(
        method: Method,
        url: string,
        payload: TPayload,
        options?: ApiRequestOptions,
    ) => Promise<TResponse> = async <TPayload>(
        method: Method,
        url: string,
        payload: TPayload,
        options: ApiRequestOptions = {},
    ) => {
        const { download = false, headers = {} } = options;
        const token = await acquireAccessTokenSilent();

        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }
        let responseType: ResponseType = 'json';

        if (download) {
            responseType = 'blob';
            headers.accept = '*/*';
        }

        const decodedToken = decodeToken(token);
        headers['X-Company'] = decodedToken.companyCode;
        headers['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

        switch (method) {
            case Method.GET:
                return api
                    .get(url, { headers, responseType })
                    .then((response) => (responseType === 'blob' ? response : response.data))
                    .catch((error: AxiosError) => {
                        if (appInsights !== null) {
                            trackException(appInsights, error);
                        }
                        throw new ApiError(
                            error.request.status,
                            error.request.status === 404 || error.request.status === 500,
                        );
                    });
            case Method.POST:
                return api
                    .post(url, payload, { headers, responseType })
                    .then((response) => (responseType === 'blob' ? response : response.data))
                    .catch((error: AxiosError) => {
                        if (appInsights !== null) {
                            trackException(appInsights, error);
                        }
                        throw new ApiError(
                            error.request.status,
                            error.request.status === 404 || error.request.status === 500,
                        );
                    });
            case Method.PATCH:
                return api
                    .patch(url, payload, { headers })
                    .then((response) => response.data)
                    .catch((error: AxiosError) => {
                        if (appInsights !== null) {
                            trackException(appInsights, error);
                        }
                        throw new ApiError(
                            error.request.status,
                            error.request.status === 404 || error.request.status === 500,
                        );
                    });
            case Method.PUT:
                return api
                    .put(url, payload, { headers })
                    .then((response) => response.data)
                    .catch((error: AxiosError) => {
                        if (appInsights !== null) {
                            trackException(appInsights, error);
                        }
                        throw new ApiError(
                            error.request.status,
                            error.request.status === 404 || error.request.status === 500,
                        );
                    });
            case Method.DELETE:
                return api
                    .delete(url, { headers })
                    .then((response) => response.data)
                    .catch((error: AxiosError) => {
                        if (appInsights !== null) {
                            trackException(appInsights, error);
                        }
                        throw new ApiError(
                            error.request.status,
                            error.request.status === 404 || error.request.status === 500,
                        );
                    });
            default:
                return Promise.reject(new Error(`bad method ${method}`));
        }
    };

    const get: <TResponse>(url: string, options?: ApiRequestOptions) => Promise<TResponse> = (
        url: string,
        options: ApiRequestOptions = {},
    ) => {
        return request(Method.GET, url, null, {
            headers: options.headers,
            download: options.download,
        });
    };

    const post: <TResponse, TPayload>(
        url: string,
        payload?: TPayload,
        options?: ApiRequestOptions,
    ) => Promise<TResponse> = <TPayload>(url: string, payload: TPayload, options: ApiRequestOptions = {}) => {
        return request(Method.POST, url, payload, { headers: options.headers, download: options.download });
    };

    const patch: <TResponse, TPayload>(
        url: string,
        payload: TPayload,
        options?: ApiRequestOptions,
    ) => Promise<TResponse> = <TPayload>(url: string, payload: TPayload, options: ApiRequestOptions = {}) => {
        return request(Method.PATCH, url, payload, { headers: options.headers });
    };

    const put: <TResponse, TPayload>(
        url: string,
        payload: TPayload,
        options?: ApiRequestOptions,
    ) => Promise<TResponse> = <TPayload>(url: string, payload: TPayload, options: ApiRequestOptions = {}) => {
        return request(Method.PUT, url, payload, { headers: options.headers });
    };

    const del: <TResponse>(url: string, options?: ApiRequestOptions) => Promise<TResponse> = (
        url: string,
        options: ApiRequestOptions = {},
    ) => {
        return request(Method.DELETE, url, null, { headers: options.headers });
    };

    return {
        get,
        post,
        patch,
        put,
        del,
    };
};

export default useApi;

import MenuNumberIndicator from './MenuNumberIndicator';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { darkerGrey, font, iconSize, linkHoverBackgound, primaryMerBlue, spaceS, white } from 'styles/variables';

export const NavigationLinkStyle = `
    height: 2.75rem;
    display: grid;
    padding: 0 1.25rem;
    grid-template-columns: ${iconSize} 1fr auto;
    gap: ${spaceS};
    align-items: center;
    color: ${darkerGrey};
    text-decoration: none;
    outline: none;
    &:hover,
    &:active,
    &:focus {
        background: ${white};
        outline: none;
    }
    overflow: hidden;
    text-align: left;

    &.collapsed {
        grid-template-columns: 1fr auto;
        &:active,
        &:focus{
            background: ${linkHoverBackgound};
            outline: none;
        }
    }
`;

const NavigationLink = styled(NavLink)`
    ${NavigationLinkStyle}
    white-space: nowrap;

    &.active {
        background: ${linkHoverBackgound};
        font-weight: ${font.weight.semiBold};
        color: ${primaryMerBlue};
    }
`;

const IconContainer = styled.span`
    height: ${iconSize};
`;

type MenuItemProps = {
    item: {
        title: string;
        name: string;
        path: string;
        icon?: JSX.Element;
        numberIndicatorValue?: number;
        showNumberIndicator?: boolean;
    };
    collapsed?: boolean;
    onClick?: () => void;
};

export default function MenuItem({
    item: { path, icon, title, name, numberIndicatorValue = 0, showNumberIndicator },
    collapsed = false,
    onClick,
}: MenuItemProps): JSX.Element {
    return (
        <NavigationLink
            onClick={onClick}
            className={collapsed ? 'collapsed' : undefined}
            data-testid={`menuLink_${name}`}
            to={path}
            end
        >
            {!collapsed ? <IconContainer>{icon}</IconContainer> : null}
            <span>{title}</span>
            {showNumberIndicator && numberIndicatorValue > 0 && (
                <MenuNumberIndicator dataTestId={`numberIndicator_${name}`} number={numberIndicatorValue} />
            )}
        </NavigationLink>
    );
}
